import {VideoFilterOptions} from '@/types/video';

import {tensorFlowBodyPixInit} from './tensorFlowBodyPix';

declare global {
    interface Window {
        vidyoConnector: any;
    }
}

async function registerFilter(type: VideoFilterOptions, customBg?: string) {
    if (window['VIDYO_IS_BANUBA_ENABLED'] === true) {
        if (!window.banubaPluginReady) {
            return console.error('Banuba not ready');
        }

        if (type === 'blur') {
            await window.banuba.applyEffect('blur');
            return window.vidyoConnector.RegisterLocalCameraStreamInterceptor(window.banuba.effectBackground);
        } else if (type === 'bg_image') {
            await window.banuba.applyEffect('custom_bg', customBg);
            return window.vidyoConnector.RegisterLocalCameraStreamInterceptor(window.banuba.effectBackground);
        }

        return;
    }

    if (type === 'blur') {
        const steamWrapper = await tensorFlowBodyPixInit(type);
        setTimeout(() => {
            window.vidyoConnector.RegisterLocalCameraStreamInterceptor(steamWrapper);
        }, 0);
        return;
    }

    if (type === 'bg_image') {
        const steamWrapper = await tensorFlowBodyPixInit(type, customBg);
        setTimeout(() => {
            window.vidyoConnector.RegisterLocalCameraStreamInterceptor(steamWrapper);
        }, 0);
        return;
    }
}

async function unregisterFilter() {
    return await window.vidyoConnector.UnregisterLocalCameraStreamInterceptor();
}

export {registerFilter, unregisterFilter};
