import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';

import ErrorBoundary from '@/components/base/MHErrorBoundary';
import {store} from '@/redux/store';
import {Auth0AppState, Auth0Provider} from '@/services/auth0';
import * as serviceWorker from '@/serviceWorker';

import {VideoCall} from './pages/VideoCall';
import {initializeDatadogLogs} from './services/dataDogLogs';
import {App} from './App';
import theme from './theme';

import 'react-toastify/dist/ReactToastify.css';
import './styles/global.scss';

try {
    Notification.requestPermission().then((result) => {
        console.info(`notification permission ${result}`);
    });
} catch (error) {
    // Safari doesn't return a promise for requestPermissions and it
    // throws a TypeError. It takes a callback as the first argument
    // instead.
    if (error instanceof TypeError) {
        Notification.requestPermission(() => {
            console.info(`notification permission -`);
        });
    } else {
        throw error;
    }
}

declare global {
    interface Window {
        REACT_APP_CORE_API_URL: any;
        AUTH_0_DOMAIN: any;
        AUTH_0_CLIENT_ID: any;
        AWS_CONNECT_LOGIN_URL: any;
        AWS_CONNECT_URL: any;
        REACT_APP_STATISTICS_API_URL: any;
        AWS_CONNECT_LIVENESS: any;
        LOGGING_SERVICE_URL: any;
        DISABLE_AWS_LOGIN: any;
        FUSIONCHARTS_KEY: any;
        CALENDAR_LICENSE_KEY: any;
        VIDEO_SCHEDULED_ACCESS: any;
        VIDYO_TENANT_URL: string;
        VIDYO_IS_BANUBA_ENABLED: boolean;
        DATADOG_APPLICATION_ID: string;
        DATADOG_CLIENT_TOKEN: string;
        DATADOG_ENV: string;
    }
}

const onRedirectCallback = (appState?: Auth0AppState) => {
    window.history.replaceState({}, document.title, appState?.targetUrl || window.location.pathname);
};

if (import.meta.env.VITE_IS_LOCAL === 'true') {
    window.REACT_APP_CORE_API_URL = import.meta.env.VITE_APP_CORE_API_URL;
    window.AUTH_0_DOMAIN = import.meta.env.VITE_AUTH_0_DOMAIN;
    window.AUTH_0_CLIENT_ID = import.meta.env.VITE_AUTH_0_CLIENT_ID;
    window.AWS_CONNECT_LOGIN_URL = import.meta.env.VITE_AWS_CONNECT_LOGIN_URL;
    window.AWS_CONNECT_URL = import.meta.env.VITE_AWS_CONNECT_URL;
    window.REACT_APP_STATISTICS_API_URL = import.meta.env.VITE_APP_STATISTICS_API_URL;
    window.AWS_CONNECT_LIVENESS = import.meta.env.VITE_AWS_CONNECT_LIVENESS;
    window.LOGGING_SERVICE_URL = import.meta.env.VITE_LOGGING_SERVICE_URL;
    window.DISABLE_AWS_LOGIN = import.meta.env.VITE_DISABLE_AWS_LOGIN;
    window.FUSIONCHARTS_KEY = import.meta.env.VITE_FUSIONCHARTS_KEY;
    window.CALENDAR_LICENSE_KEY = import.meta.env.VITE_CALENDAR_LICENSE_KEY;
    window.VIDEO_SCHEDULED_ACCESS = import.meta.env.VITE_VIDEO_SCHEDULED_ACCESS;
    window.VIDYO_TENANT_URL = import.meta.env.VITE_VIDYO_TENANT_URL;
    window.VIDYO_IS_BANUBA_ENABLED = import.meta.env.VITE_VIDYO_IS_BANUBA_ENABLED === 'true';
    window.DATADOG_APPLICATION_ID = import.meta.env.VITE_DATADOG_APPLICATION_ID;
    window.DATADOG_CLIENT_TOKEN = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;
    window.DATADOG_ENV = import.meta.env.VITE_DATADOG_ENV;
}

initializeDatadogLogs();

const auth0InitOptions = {
    domain: window.AUTH_0_DOMAIN,
    client_id: window.AUTH_0_CLIENT_ID,
    max_age: '360000',
    leeway: 400,
    audience: 'https://care-engine',
    redirect_uri: window.location.origin,
    oidcClient: {
        clockTolerance: 400000,
    },
};

const isVideoCall = window.location.pathname.match(/video-call/);

const EntryPoint = () => (
    <ErrorBoundary>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ToastContainer
                    toastClassName="toastClassName"
                    bodyClassName="toastBodyClassName"
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    pauseOnHover
                    rtl={false}
                    draggable={false}
                    closeButton={false}
                />
                {isVideoCall ? (
                    <VideoCall />
                ) : (
                    <Auth0Provider initOptions={auth0InitOptions} onRedirectCallback={onRedirectCallback}>
                        <Provider store={store}>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </Provider>
                    </Auth0Provider>
                )}
            </ThemeProvider>
        </StyledEngineProvider>
    </ErrorBoundary>
);

ReactDOM.render(<EntryPoint />, document.getElementById('root') || document.createElement('div'));

serviceWorker.unregister();
